import React from 'react'
import { Link } from "react-router-dom"

const Footer = ({settings}) => {
  return (
    <>      
        {settings ? 
          <footer>
            <div className="container">
              <div className='footer-block'>
                {settings[0].footer_text ? 
                  <h6 dangerouslySetInnerHTML={{__html:settings[0].footer_text}} />
                : null}
                {settings[0].footer_phone ? 
                  <p dangerouslySetInnerHTML={{__html:'' + settings[0].footer_phone}} />
                : null }            
                {settings[0].footer_email ? 
                  <p>E: <a href={`mailto:${settings[0].footer_email}`}>{settings[0].footer_email}</a></p>
                : null }
              </div>
              <div className='footer-block'>                
                  {settings[0].facebook ? 
                      <a href={settings[0].facebook} target='_blank' className='facebook'></a>                    
                  : null }
                  {settings[0].twitter ?                     
                      <a href={settings[0].twitter} target='_blank' className='twitter'></a>                    
                  : null }
                  {settings[0].youtube ?                     
                      <a href={settings[0].youtube} target='_blank' className='youtube'></a>                    
                  : null }
                  {settings[0].linkedin ?                     
                      <a href={settings[0].linkedin} target='_blank' className='linkedin'></a>                    
                  : null }   
                    {settings[0].instagram ?                     
                      <a href={settings[0].instagram} target='_blank' className='instagram'></a>                    
                  : <a href="https://www.instagram.com/psm_vision_limited_uk/" target='_blank' className='instagram'></a> }               
              </div>
              <div className='footer-block'>
                <p>Copyright &copy; 2022 PSM VISION LIMITED, All Rights are reserved. <Link to="/terms-conditions">Terms &amp; Conditions</Link></p>
              </div>   
            </div>   
          </footer>
        : null}
    </>
  )
}

export default Footer